<template>
  <div class="row">
    <div class="col-sm-4" v-for="mod in mods" :key="mod.id">
      <div class="card mb-3">
        <!--begin::Layout Builder Notice-->
        <div class="mod-tooltip">
          <a :href="'/mod/' + (mod.transliteratedTitle ? mod.transliteratedTitle : mod.id)" @click.prevent="goToMod(mod.transliteratedTitle ? mod.transliteratedTitle : mod.id)">
            <img
              loading="lazy"
              :src="
                mod.media && mod.media.length > 0
                  ? mod.media[0].previewLink
                    ? mod.media[0].previewLink
                    : mod.media[0].link
                  : '/media/svg/avatars/blank.svg'
              "
              class="card-img-top"
            />
          </a>
          <span
            class="mod-tooltiptext mod-tooltip-right"
            v-if="
              trimDescription($getLocalizedValue(mod.description, $i18n.locale))
            "
          >
            {{
              trimDescription($getLocalizedValue(mod.description, $i18n.locale))
            }}
          </span>
        </div>

        <div class="card-body mod-card-body">
          <a
            :href="'/mod/' + (mod.transliteratedTitle ? mod.transliteratedTitle : mod.id)"
            @click.prevent="goToMod(mod.transliteratedTitle ? mod.transliteratedTitle : mod.id)"
            class="card-title text-dark text-hover-primary fw-bolder fs-2"
            >{{ mod.title }}</a
          >
          <br />

          <div v-if="showAuthor === true">
            <template
                v-for="(author, authorIndex) in mod.authors"
                :key="author"
            >
              <a
                  :href="'/author/' + (author.transliteratedName ? author.transliteratedName : author.id)"
                  @click.prevent=" saveLastPageData();
                  this.$router.push({
                    name: 'author',
                    params: { id: (author.transliteratedName ? author.transliteratedName : author.id) },
                  })
                "
                  class="card-title text-hover-primary fw-bolder fs-7"
                  style="color: #424242"
              >
                <template v-if="author.avatar">
                  <img
                      class="me-1 fs-5"
                      :src="author.avatar"
                      style="width: 14px; height: 14px; border-radius: 4px"
                  />
                  {{ author.name }}
                </template>
                <template v-else>
                  <i class="fa fa-user me-1 fs-5" style="color: #009ef7"></i
                  >&nbsp;{{ author.name }}
                </template>
              </a>
              <span
                  class="card-title fw-bolder fs-7"
                  v-if="authorIndex !== mod.authors.length - 1"
              >,&nbsp;&nbsp;</span
              >
            </template>
          </div>
          <p class="card-text" style="display: none">
            {{
              trimDescription($getLocalizedValue(mod.description, $i18n.locale))
            }}
          </p>

          <span class="card-title">
            {{ $t("mod_type_" + mod.type + "_" + mod.subcategory) }}
          </span>

          <p class="card-text">
            <small
              class="text-muted text-hover-primary"
              style="cursor: pointer"
              @click="searchByTag(tag)"
              v-for="(tag, tagIndex) in mod.tagIds"
              :key="1000 + tag"
              >{{ getTagName(tag)
              }}{{ tagIndex !== mod.tagIds.length - 1 ? ", " : "" }}</small
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, nextTick } from "vue";

export default defineComponent({
  name: "ModsView",
  props: {
    mods: { type: Array },
    modsTotal: { type: Number },
    showAuthor: { type: Boolean, default: false },
  },

  computed: {
    tags() {
      return this.$store.getters.getTagsMap;
    },
  },

  watch: {
    mods: function () {
      nextTick(() => {
        this.initTooltips();
      });
    },
  },

  data: function () {
    return {
      descriptionMaxLength: 400,
    };
  },

  mounted: function () {
    this.initTooltips();
  },

  methods: {
    saveLastPageData() {
      if (this.$router.currentRoute.value.name == "mods") {
        window.sessionStorage.setItem("modsLastScrollY", window.scrollY);
        window.sessionStorage.setItem("lastMods", JSON.stringify(this.mods));
        window.sessionStorage.setItem(
          "lastModsRouteName",
          this.$router.currentRoute.value.name
        );
        window.sessionStorage.setItem(
          "lastModsLocationSearch",
          window.location.search
        );
        window.sessionStorage.setItem("lastModsTotal", this.modsTotal);
      }
    },

    // go to the mod and save place where we have been
    goToMod(modId) {
      this.saveLastPageData();
      this.$router.push({ name: "mod", params: { id: modId } });
    },

    searchByTag(tagId) {
      if (this.$router.currentRoute.value.name == "mods") return;

      this.$router.push({
        name: "mods",
        query: { tags: tagId },
      });
    },

    trimDescription(str) {
      if (!str) return "";
      if (str.length < this.descriptionMaxLength) return str;

      return str.substr(0, this.descriptionMaxLength) + "...";
    },

    getTagName(tagId) {
      let tags = this.tags;
      if (tags && (tagId in tags)) {
        return this.$getLocalizedValue(tags[tagId].name, this.$i18n.locale);
      } else {
        return "";
      }
    },

    initTooltips() {
      let tooltips = document.querySelectorAll(".mod-tooltip");
      for (let i = 0; i < tooltips.length; i++) {
        tooltips[i].addEventListener("mouseenter", () => {
          let t = tooltips[i];
          let text = t.querySelector(".mod-tooltiptext");
          if (text != null) {
            this.setSpanPosition(text);
          }
        });
      }

      let tooltipTexts = document.querySelectorAll(".mod-tooltiptext");
      for (let i = 0; i < tooltipTexts.length; i++) {
        this.setSpanPosition(tooltipTexts[i]);
      }
    },

    setSpanPosition(span) {
      let rect = span.getBoundingClientRect();

      if (
        span.classList.contains("mod-tooltip-right") &&
        rect.right + 100 >=
          (window.innerWidth || document.documentElement.clientWidth)
      ) {
        span.classList.remove("mod-tooltip-right");
        span.classList.add("mod-tooltip-left");
      } else if (
        span.classList.contains("mod-tooltip-left") &&
        rect.left <= 0
      ) {
        span.classList.remove("mod-tooltip-left");
        span.classList.add("mod-tooltip-right");
      }
    },
  },
});
</script>
<style>
.mod-card-body {
  padding: 0.7rem 2.25rem !important;
}

.card-img-top {
  width: 100%;
  height: 25vh;
  object-fit: cover;
  cursor: pointer;
  transition: 0.5s;
  transition-delay: 0.1s;
}

.card-img-top:hover {
  opacity: 0.8;
}

.mod-tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

@media (max-width: 575px) {
  .mod-tooltiptext {
    display: none;
  }
}

.mod-tooltip .mod-tooltiptext {
  white-space: pre-line;
  visibility: hidden;
  width: 100%;
  background-color: black;
  color: #fff;
  border-radius: 6px;
  padding: 5px 5px 5px 5px;
  position: absolute;
  z-index: 1;
  /* Fade in tooltip - takes 1 second to go from 0% to 100% opac: */
  opacity: 0;
  transition: opacity 1s;
}

.mod-tooltip-right {
  top: -5px;
  left: 105%;
}

.mod-tooltip-left {
  top: -5px;
  right: 105%;
}

.mod-tooltip .mod-tooltip-right::after {
  content: " ";
  position: absolute;
  top: 50%;
  right: 100%; /* To the left of the tooltip */
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent black transparent transparent;
}

.mod-tooltip .mod-tooltip-left::after {
  content: " ";
  position: absolute;
  top: 50%;
  left: 100%; /* To the right of the tooltip */
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent black;
}

.mod-tooltip:hover .mod-tooltiptext {
  visibility: visible;
  opacity: 1;
}
</style>
