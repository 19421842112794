<template>
  <div class="d-flex flex-column flex-lg-row">
    <div
      class="flex-column flex-lg-row-auto w-100 w-lg-300px w-xl-300px mb-10 mb-lg-0"
    >
      <div class="card mb-3">
        <div class="card-body">
          <div class="position-relative">
            <!--begin::Form-->
            <form class="w-100 position-relative mb-3" autocomplete="off">
              <!--begin::Icon-->
              <span
                class="svg-icon svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 translate-middle-y ms-0"
              >
                <inline-svg src="/media/icons/duotune/general/gen021.svg" />
              </span>
              <!--end::Icon-->

              <!--begin::Input-->
              <input
                ref="inputRef"
                type="text"
                class="form-control form-control-flush ps-10"
                name="search"
                :placeholder="$t('searchByModName')"
                @keydown.enter.prevent="setPage(0)"
                v-model="searchString"
              />
              <!--end::Input-->
            </form>
            <!--end::Form-->

            <div v-if="false">
              <el-select
                size="small"
                placeholder="Filter by release date"
                multiple
                v-model="releaseDateFilter"
                class="mb-2"
              >
                <el-option v-for="year in years" :key="year" :value="year">{{
                  year
                }}</el-option>
              </el-select>
            </div>

            <div class="card-title text-dark fw-bolder fs-3">
              {{ $t("gameTitle") }}
            </div>
            <label
              class="form-check form-check-sm form-check-custom form-check-solid me-5 mb-1"
              v-for="gameType in gameTypes"
              :key="gameType"
            >
              <input
                class="form-check-input"
                type="checkbox"
                v-model="selectedGameTypes"
                :value="gameType.value"
                @change="setPage(0)"
              />
              <span class="form-check-label user-select-none">
                {{ gameType.name }}
              </span>
            </label>

            <div class="card-title text-dark fw-bolder fs-3">
              {{ $t("materialTypeTitle") }}
            </div>
            <label
              class="form-check form-check-sm form-check-custom form-check-solid me-5 mb-1"
              v-for="modType in modTypes"
              :key="modType"
            >
              <input
                class="form-check-input"
                type="checkbox"
                v-model="selectedModTypes"
                :value="modType.value"
                @change="setPage(0)"
              />
              <span class="form-check-label user-select-none">
                {{ this.$t(modType.name) }}
              </span>
            </label>

            <div class="card-title text-dark fw-bolder fs-3">
              {{ $t("gameModeTitle") }}
            </div>

            <label
              class="form-check form-check-sm form-check-custom form-check-solid me-5 mb-1"
              v-for="mode in gameModes"
              :key="mode"
            >
              <input
                class="form-check-input"
                type="checkbox"
                v-model="selectedGameModes"
                :value="mode.value"
                @change="setPage(0)"
              />
              <span class="form-check-label user-select-none">
                {{ this.$t(mode.name) }}
              </span>
            </label>

            <div class="card-title text-dark fw-bolder fs-3">
              {{ $t("tagsTitle") }}
            </div>

            <label
              class="form-check form-check-sm form-check-custom form-check-solid me-5 mb-1 tag-tooltip"
              v-for="tag in currentTags"
              :key="tag"
            >
              <input
                class="form-check-input"
                type="checkbox"
                v-model="selectedTags"
                :value="tag.id"
                @change="setPage(0)"
              />
              <span class="form-check-label user-select-none">
                {{ $getLocalizedValue(tag.name, this.$i18n.locale) }}
              </span>

              <span
                class="tag-tooltiptext tag-tooltip-right"
                v-if="tagsMap[tag.id].description"
                v-html="
                  $getLocalizedValue(
                    tagsMap[tag.id].description,
                    this.$i18n.locale
                  )
                "
              ></span>
            </label>

            <!-- Оверлей с условием отображения -->
            <div class="overlay position-absolute" v-show="showOverlay">
              <div class="loader"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex-lg-row-fluid ms-lg-7 ms-xl-10">
      <div
        class="card mb-3"
        v-if="mods.length > 0 && selectedTags.length === 1"
      >
        <div style="margin-left: 24px" class="mt-4">
          <h2>
            {{
              $getLocalizedValue(
                tagsMap[selectedTags[0]].name,
                this.$i18n.locale
              )
            }}
          </h2>
        </div>
        <div
          style="margin-left: 24px"
          v-if="tagsMap[selectedTags[0]].description"
          v-html="
            $getLocalizedValue(
              tagsMap[selectedTags[0]].description,
              this.$i18n.locale
            )
          "
        ></div>
      </div>

      <ModsView
        :mods="mods"
        :mods-total="modsTotal"
        :showAuthor="true"
      ></ModsView>
      <div class="card" v-if="noModsFound">
        <div class="card-body">
          <h1 style="text-align: center">{{ $t("nothing_found") }}</h1>
          <p style="text-align: center">
            {{ $t("please_check_filters") }}
          </p>
        </div>
      </div>

      <div v-if="mods.length > 0">
        <div style="border-top: 2px solid #eff2f5"></div>

        <div class="display-flex mt-2 mb-2">
          <button
            class="btn btn-sm"
            :class="{
              'btn-primary': pageSize === 12,
              'btn-secondary': pageSize !== 12,
            }"
            @click="
              pageSize = 12;
              setPage(0);
            "
          >
            12
          </button>
          <button
            class="btn btn-sm"
            :class="{
              'btn-primary': pageSize === 48,
              'btn-secondary': pageSize !== 48,
            }"
            @click="
              pageSize = 48;
              setPage(0);
            "
            style="margin-left: 4px"
          >
            48
          </button>
          <button
            class="btn btn-sm"
            :class="{
              'btn-primary': pageSize === 96,
              'btn-secondary': pageSize !== 96,
            }"
            @click="
              pageSize = 96;
              setPage(0);
            "
            style="margin-left: 4px"
          >
            96
          </button>
          <el-select
            size="small"
            placeholder="Sort by release date"
            v-model="sortBy"
            style="float: right; width: 224px"
          >
            <el-option
              value="rd"
              @click="
                sortBy = 'rd';
                setPage(0);
              "
              :label="$t('sort_by_release_date')"
            ></el-option>
            <el-option
              value="cd"
              @click="
                sortBy = 'cd';
                setPage(0);
              "
              :label="$t('sort_by_publication_date')"
            ></el-option>
          </el-select>
        </div>
      </div>
    </div>
  </div>
  <ul class="pagination" v-if="mods.length > 0">
    <li
      :class="
        currentPage === 0 ? 'page-item previous disabled' : 'page-item previous'
      "
    >
      <a
        href="javascript:;"
        v-on:click="setPage(currentPage - 1)"
        class="page-link"
        ><i class="previous"></i
      ></a>
    </li>
    <li
      v-for="p in totalPages"
      :class="currentPage == p - 1 ? 'page-item active' : 'page-item'"
      :key="p"
    >
      <a href="javascript:;" v-on:click="setPage(p - 1)" class="page-link">{{
        p
      }}</a>
    </li>
    <li
      :class="
        currentPage + 1 === totalPages
          ? 'page-item next disabled'
          : 'page-item next'
      "
    >
      <a
        href="javascript:"
        v-on:click="setPage(currentPage + 1)"
        class="page-link"
        ><i class="next"></i
      ></a>
    </li>
  </ul>

  <!--end::Layout Builder Notice-->
</template>
<script>
import { defineComponent } from "vue";
import ApiService from "@/core/services/ApiService";
import ModsView from "../components/ModsView";

export default defineComponent({
  name: "mods",
  components: {
    ModsView,
  },
  data: function () {
    return {
      releaseDateFilter: [],
      mods: [],
      modsTotal: 0,
      noModsFound: false,
      currentTags: [],
      tagsAfterFiltering: [],
      pageSize: 12,
      currentPage: 0,
      totalPages: 1,
      descriptionMaxLength: 400,
      searchString: "",
      showOverlay: true,

      sortBy: "rd",
      selectedGameModes: [],
      selectedGameTypes: [],
      selectedModTypes: [],
      selectedTags: [],
      isOpen: false,

      years: [
        2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012,
        2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024,
      ],

      gameTypes: [
        {
          value: 0,
          name: "The First Encounter",
        },
        {
          value: 1,
          name: "The Second Encounter",
        },
      ],

      gameModes: [
        {
          value: 0,
          name: "singlePlayer",
        },
        {
          value: 2,
          name: "singleAndCoop",
        },
        {
          value: 1,
          name: "cooperative",
        },
        {
          value: 3,
          name: "deathmatch",
        },
        {
          value: 4,
          name: "allGameModes",
        },
      ],

      modTypes: [
        {
          value: 0,
          name: "mod_type_map",
        },
        {
          value: 1,
          name: "mod_type_mod",
        },
        {
          value: 2,
          name: "mod_type_skin",
        },
        {
          value: 3,
          name: "mod_type_resource",
        },
        {
          value: 4,
          name: "mod_type_dll",
        },
      ],
    };
  },

  computed: {
    tags() {
      return this.$store.getters.getTags;
    },
    tagsMap() {
      return this.$store.getters.getTagsMap;
    },
  },

  mounted: function () {
    this.initTooltips();
  },

  created: function () {
    document.title = this.$t("mods") + " - Gro Repository";

    if (this.$route.query.page) {
      this.currentPage = this.$route.query.page - 1;
    }

    if (this.$route.query.str) {
      this.searchString = this.$route.query.str;
    }

    if (this.$route.query.subcategory) {
      this.selectedGameModes = this.$route.query.subcategory.split(",");
    }

    if (this.$route.query.type) {
      this.selectedModTypes = this.$route.query.type.split(",");
    }

    if (this.$route.query.game) {
      this.selectedGameTypes = this.$route.query.game.split(",");
    }

    if (this.$route.query.tags) {
      this.selectedTags = this.$route.query.tags.split(",");
    }

    if (this.$route.query.count) {
      this.pageSize = Number.parseInt(this.$route.query.count);
    }

    if (this.$route.query.sb) {
      this.sortBy = this.$route.query.sb;
    }

    this.setPage(this.currentPage, true);
  },

  methods: {
    toggle() {
      this.isOpen = !this.isOpen;
    },

    setPage: async function (pageIndex, noReplacePageArguments) {
      this.noModsFound = false;
      this.currentPage = pageIndex;
      this.mods = [];
      this.showOverlay = true;

      let start = this.currentPage * this.pageSize;

      let response = null;

      if (
        window.sessionStorage.getItem("lastModsRouteName") == "mods" &&
        window.sessionStorage.getItem("lastModsLocationSearch") ==
          window.location.search
      ) {
        response = {
          data: {
            data: JSON.parse(window.sessionStorage.getItem("lastMods")),
            total: parseInt(
              JSON.parse(window.sessionStorage.getItem("lastModsTotal"))
            ),
          },
        };
        window.scrollY = parseInt(
          window.sessionStorage.getItem("modsLastScrollY")
        );
      } else {
        document.documentElement.scrollTop = document.body.scrollTop = 0;
        response = await ApiService.get(
          "api",
          `mods?start=${start}&count=${this.pageSize}&str=${this.searchString}&subcategory=${this.selectedGameModes}&type=${this.selectedModTypes}&game=${this.selectedGameTypes}&tags=${this.selectedTags}&so=${this.sortBy}`
        ).catch((reason) => console.log(reason));
      }

      window.sessionStorage.setItem("lastModsRouteName", "null");
      window.sessionStorage.setItem("lastModsLocationSearch", "null");

      this.showOverlay = false;

      if (response && response.data) {
        this.modsTotal = response.data.total;
        this.totalPages = Math.ceil(response.data.total / this.pageSize);
        this.mods = response.data.data;
      }

      let obj = {
        path: "mods",
        query: { page: this.currentPage + 1 },
      };

      if (this.searchString) {
        obj.query.str = this.searchString;
      }

      if (this.selectedGameModes && this.selectedGameModes.length > 0) {
        obj.query.subcategory = this.selectedGameModes.join();
      }

      if (this.selectedGameTypes && this.selectedGameTypes.length > 0) {
        obj.query.game = this.selectedGameTypes.join();
      }

      if (this.selectedModTypes && this.selectedModTypes.length > 0) {
        obj.query.type = this.selectedModTypes.join();
      }

      if (this.pageSize !== 12) {
        obj.query.count = this.pageSize;
      }

      if (this.sortBy !== "rd") {
        obj.query.sb = this.sortBy;
      }

      if (this.selectedTags && this.selectedTags.length > 0) {
        obj.query.tags = this.selectedTags.join();
        if (response.data.tags && response.data.tags.length > 0) {
          this.currentTags = this.tags.filter(
            (tag) => response.data.tags.indexOf(tag.id) !== -1
          );
        } else {
          this.currentTags = this.tags;
        }
      } else {
        this.currentTags = this.tags;
      }

      if (this.mods.length === 0) {
        this.noModsFound = true;
      }

      if (!noReplacePageArguments) {
        this.$router.replace(obj);
      }
    },

    initTooltips() {
      let tooltips = document.querySelectorAll(".tag-tooltip");
      for (let i = 0; i < tooltips.length; i++) {
        tooltips[i].addEventListener("mouseenter", () => {
          let t = tooltips[i];
          let text = t.querySelector(".tag-tooltiptext");
          if (text != null) {
            this.setSpanPosition(text);
          }
        });
      }

      let tooltipTexts = document.querySelectorAll(".tag-tooltiptext");
      for (let i = 0; i < tooltipTexts.length; i++) {
        this.setSpanPosition(tooltipTexts[i]);
      }
    },

    setSpanPosition(span) {
      let rect = span.getBoundingClientRect();

      if (
        span.classList.contains("tag-tooltip-right") &&
        rect.right + 100 >=
          (window.innerWidth || document.documentElement.clientWidth)
      ) {
        span.classList.remove("tag-tooltip-right");
        span.classList.add("tag-tooltip-left");
      } else if (
        span.classList.contains("tag-tooltip-left") &&
        rect.left <= 0
      ) {
        span.classList.remove("tag-tooltip-left");
        span.classList.add("tag-tooltip-right");
      }
    },
  },
});
</script>
<style>
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5); /* Полупрозрачный белый цвет */
  z-index: 10; /* Убедитесь, что оверлей находится поверх карточки */
  display: flex;
  justify-content: center;
  align-items: center; /* Центрирование содержимого оверлея, если оно есть */
}

.loader {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.tag-tooltip {
  position: relative;
  display: inline-block;
}

@media (max-width: 575px) {
  .tag-tooltiptext {
    display: none;
  }
}

.tag-tooltip .tag-tooltiptext {
  white-space: pre-line;
  visibility: hidden;
  width: 100%;
  background-color: black;
  color: #fff;
  border-radius: 6px;
  padding: 5px 5px 5px 5px;
  position: absolute;
  z-index: 1;
  /* Fade in tooltip - takes 1 second to go from 0% to 100% opac: */
  opacity: 0;
  transition: opacity 1s;
}

.tag-tooltip-right {
  top: -5px;
  left: 80%;
}

.tag-tooltip-left {
  top: -5px;
  right: 105%;
}

.tag-tooltip .tag-tooltip-right::after {
  content: " ";
  position: absolute;
  top: 50%;
  right: 100%; /* To the left of the tooltip */
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent black transparent transparent;
}

.tag-tooltip .tag-tooltip-left::after {
  content: " ";
  position: absolute;
  top: 50%;
  left: 100%; /* To the right of the tooltip */
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent black;
}

.tag-tooltip:hover .tag-tooltiptext {
  visibility: visible;
  opacity: 1;
}
</style>
